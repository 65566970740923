<template>
  <v-theme-provider dark>
    <base-section
      id="contact-us"
      class="primary"
      space="10"
    >
      <v-container>
        <v-row justify="center">
          <v-col cols="9">
            <base-title
              :space="$vuetify.breakpoint.mdAndUp ? 0 : 4"
              title=" ¡ CONVOCATORIA DE  RECERTIFICACIÓN 2025-A!"
            />

            <base-body space="0">
              <span class="white--text">
                Ya se encuentra abierta la Convocatoria de Recertificación para el ciclo 2025-A.
              </span>
              <p class="white--text">
                Consulta las bases en el siguiente link
              </p>
            </base-body>
          </v-col>

          <v-col
            cols="12"
            md="3"
          >
            <!--<base-btn
              :block="$vuetify.breakpoint.smAndDown"
              color="white"
              large
              outlined
              :href="path_convocatoria_certificacion"
              target="_blank"
            >
              CONVOCATORIA CERTIFICACIÓN 2023
            </base-btn>-->

            <base-btn
              class="mt-2 mr-n1"
              :block="$vuetify.breakpoint.smAndDown"
              color="white"
              large
              outlined
              :href="path_convocatoria_recertificacion"
              target="_blank"
            >
              CONVOCATORIA RECERTIFICACIÓN 2025-A
            </base-btn>
          </v-col>
        </v-row>
      </v-container>
    </base-section>
  </v-theme-provider>
</template>

<script>
  export default {
    name: 'ConvocatoriaRecertificacion',
    data: () => ({
      path_convocatoria_recertificacion:process.env.VUE_APP_BASE_URL+'/convocatorias/2025/convocatoria_recertificacion_2025A.pdf',
      //path_convocatoria_recertificacion:process.env.VUE_APP_BASE_URL+'/convocatorias/2024/convocatoria_recertificacion_2024B.pdf',
      //path_convocatoria_recertificacion:process.env.VUE_APP_BASE_URL+'/convocatorias/2023/convocatoria_recertificacion_2023B.pdf',
    }),
    methods:{

    }
  }
</script>
